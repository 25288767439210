import React, { Component, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';


import ReactDatatable from '@ashvin27/react-datatable';

import { EmailTemplateEdit } from './emailtemplateEdit.js';
import { getemailTemplateList } from '../../api/api.js';
import { Col, Container, Row } from 'react-bootstrap';
import Header from '../../Components/Header.js';
import Sidebar from '../../Components/Sidebar.js';


export default function EmailTemplateList(props) {


  const navigate = useNavigate();
  var location = useLocation();
  console.log("locations", location);
  const { pathname, state } = location;
  const path = pathname.split("/")[1]
  console.log("pathname,stae", pathname, state, path)



  const [emailTemplateList, setEmailTemplateList] = useState([])


  const userInfo = (record) => {
    console.log("useRres", record);
    navigate(`/emailtemplateEdit/${record.Type}`, { state: record });
  }

  const columns = [
    {
      key: "",
      text: "S.no",
      className: "text-center w_80",
      align: "center",
      sortable: true,
      cell: (record, index) =>
        <div>{index + 1}
        </div>

    },
    {
      key: "Type",
      text: "Type",
      className: "text-center w_150 default_font",
      align: "center",
    },
    {
      key: "Content",
      text: "Content",
      className: "text-center w_500 html_div",
      align: "center",
      cell: record => (
        record.Content.length > 80 ?
          <div className='d-flex justify-content-center' dangerouslySetInnerHTML={{ __html: record.Content.slice(0, 80) + "..." }}></div>
          :
          <div className='d-flex justify-content-center' dangerouslySetInnerHTML={{ __html: record.Content }}></div>

      )
    },


    {
      key: "Edit",
      text: "Edit",
      className: "text-center w_130",
      align: "center",
      cell: record =>
        <di>{console.log("records", record)}<button className='orange_small_primary ' onClick={() => userInfo(record)} >Edit</button></di>
    },

  ]

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Emailtemplates",
    no_data_text: "No Email Templates found!",
    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: false,
    show_filter: false,
    show_pagination: true,
    show_info: true,
  };

  const extraButtons = [
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
    },
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
      onDoubleClick: (event) => {
        console.log("doubleClick");
      },
    },
  ];

  useEffect(() => {
    getUsers();
  }, [])

  const getUsers = async () => {
    var resp = await getemailTemplateList();
    console.log("responsesss", resp);
    if (resp.data) {
      setEmailTemplateList(resp.data);
    }
  }



  return (

    <>

      <Container fluid className="common_bg position-relative">
        <div className="liner"></div>
        <Row>
          <Col xl={2} lg={0} className='p-0 d-none d-xl-block'>
            <Sidebar />
          </Col>
          <Col xl={10} lg={12}>
            <Header title={'Email Template List'} />
            <div className='py-5'>
              <div className="page-header">
                <nav aria-label="breadcrumb">
                </nav>
              </div>
              <div className="common_page_scroller mt-4">
                <div className="exchange_table_holder dashboard_box rounded-3">
                  <ReactDatatable
                    config={config}
                    records={emailTemplateList}
                    columns={columns}
                    extraButtons={extraButtons}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row >
      </Container >
    </>

  )

}
